<template>
  <div>
    <el-calendar v-model="date">
    </el-calendar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date()
    }
  }
}
</script>

<style  scoped>
</style>
